var config = {
    dateFormat: "DD-MM-YYYY",
    datepickerDefaultOptions: {
        format: 'DD-MM-YYYY'
    },
    datetimepickerDefaultOptions: {
        format: 'DD-MM-YYYY HH:mm',
        showTodayButton: true,
        showClose: true
    },
    dataTableDefaultConfig: {
        fnDrawCallback: function( oSettings ) {
            $('div.dataTables_wrapper div.dataTables_filter input').removeClass("input-sm");
        },
        responsive: false,
        sScrollX: "100%",
        sScrollXInner: "100%",
        bSort : false,
        dom: '<"top"f>t<"bottom"p>',
        language: {
            paginate: {
                previous: "&laquo;",
                next: "&raquo;"
            }
        }
    }
};

var app = {

    $el: null,
    $pageNav: null,

    init: function() {

        this.$el = null;//$(".filters");
        this.$navbar = $(".navbar");
        this.$mainNav = $(".navbar-nav");
        
        if ($(".js-form-validity").length > 0) {
            this.formsValidate();
        }
        
        $('body').tooltip({
            container: 'body',
            selector: '[data-toggle="tooltip"]'
        });

        if ($("body").hasClass("home")) {
            this.sliderMain.init();
        }

        if($(".slider-steps").length > 0) {
            this.sliderSteps.init();
        }
        if($(".slider-houses").length > 0) {
            this.sliderHouses.init();
        }
        if($(".slider-certificate").length > 0) {
            this.sliderCertificate.init();
        }
        if($(".slider-client-opinion").length > 0) {
            this.sliderClientOpinion.init();
        }
        if($(".inline-svg").length > 0) {
            $(".inline-svg").inlineSVG();
        }
        if($(".breadcrumb").length > 0) {
            this.breadcrumbs();
        }

        if($(".header__gallery").length > 0) {
            this.lightGalleryInit();
        }

        if($(".tabs").length > 0) {
            this.tabs.init();
        }

        if($(".filter__bar").length > 0) {
            this.noUi.init();
        }

        if($(".article-list").length > 0) {
            this.articleList.init();
        }

        this.sliderProjects.init();
        this.gridGallery.init();
        this.houseGallery.init();
        this.navMobile.init();
        this.accordion.init();
        this.map.init();

        $(".sections > section").equalHeight();
        $(".sections > section").equalHeight({ target: '.sh-box2' });

        this.connectEvents();
    },

    connectEvents: function() {
        $(window).on("scroll", $.proxy(this.onScroll, this));
        $(window).on("resize", $.proxy(this.onResize, this));
        $(window).on("load", $.proxy(this.onLoad, this));
        $("[data-go-to]").on("click", $.proxy(this.goTo, this));
    },
    
    onLoad: function() {
        if($(".grid-box").length > 0) {
            this.msnry.init();
            this.galleryMasonry.init();
        }
        this.galleryColumnEqual.init();
        this.scrollBarGallery1.init();
        this.scrollBarGallery2.init();

        $('#preloader').delay(900).fadeOut(800);
    },

    onScroll: function() {

    },

    onResize: function() {
        this.breadcrumbs();
        this.galleryColumnEqual.init();
    },
    
    scrollTo: function(id) {
        $('html, body').stop().animate({
            scrollTop: $(id).offset().top - this.$navbar.height()
        }, 1000, 'easeInOutExpo');
    },
    
    goTo: function(e) {
        var $target = $(e.currentTarget).data("go-to");
        this.scrollTo($target);  
    },
    
    formsValidate: function() {
        $.validator.setDefaults({
            lang: 'pl',
            errorElement: "span",
            errorClass: "help-block",
            highlight: function (element, errorClass, validClass) {
                $(element).closest('.form-group').addClass('has-error');
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).closest('.form-group').removeClass('has-error');
            },
            errorPlacement: function (error, element) {
                if (element.parent('.input-group').length || element.prop('type') === 'checkbox' || element.prop('type') === 'radio') {
                    error.insertAfter(element.parent());
                } else {
                    error.insertAfter(element);
                }
            }
        });
        $(".js-form-validity").validate();
    },

    breadcrumbs: function() {
        var $el = $(".breadcrumb-wrapper"),
            $inner = $el.find(".breadcrumb");

        if (window.innerWidth < 768) {
            if ($el.outerWidth() < $inner.outerWidth()) {
                $el.addClass("to-right");
            } else {
                $el.removeClass("to-right");
            }
        } else {
            $el.removeClass("to-right");
        }
    },

    lightGalleryInit: function() {
        var $trigger = $(".js-gallery-trigger"),
            $gallery = $trigger.siblings(".header__gallery");

        $gallery.lightGallery({
            thumbnail: true
        });

        $trigger.on("click", function() {
            $(this).siblings(".header__gallery").find("li:first-child").trigger("click");
        });
    }
};


app.sliderMain = {

    $el: null,
    $swiper: null,
    $pagination: null,
    swiper: null,

    init: function() {
        this.$el = $(".header--home");
        this.$swiper = this.$el.find(".swiper-container");
        this.$pagination = this.$el.find(".slider__pagination");
        this.$slidePrevBtn = this.$el.find(".js-slide-prev");
        this.$slideNextBtn = this.$el.find(".js-slide-next");

        this.swiper = new Swiper(this.$swiper[0], {
            onSlideChangeStart: $.proxy(this.onSlideChangeStart, this),
            paginationClass:'slider__pagination-item',
            paginationActiveClass:'active',
            spaceBetween: 0,
            effect: 'fade',
            autoplay: 7000,
            simulateTouch: false,
            loop: true,
            roundLengths: true,
            onlyExternal: true
        });

        $(document).ready(function(){
            $('.nav-info img').resizeOnApproach({
                elementDefault: 60,
                elementClosest: 80,
                triggerDistance: 100
            });
        });

        this.$swiper.find(".swiper-slide").fullHeight();

        this.connectEvents();
    },
    
    connectEvents: function() {
        this.$pagination.find(".slider__pagination-item").on("click", $.proxy(this.slideTo, this));
        this.$swiper.on("click", ".js-slide-prev", $.proxy(this.slidePrev, this));
        this.$swiper.on("click", ".js-slide-next", $.proxy(this.slideNext, this));
    },

    onSlideChangeStart: function(swiper) {
        $('.slider__pagination-item').removeClass('active');
        $('.slider__pagination-item').eq(swiper.realIndex).addClass('active');
    },

    slideTo: function(e) {
        var $target = $(e.currentTarget),
            realIndex = $target.index(),
            index = this.$swiper.find("[data-index='"+ realIndex +"']:first").index();

        this.swiper.slideTo(index);
        $target.addClass("active").siblings().removeClass('active');
    },

    slidePrev: function() {
        this.swiper.slidePrev();
    },

    slideNext: function() {
        this.swiper.slideNext();
    }
};


app.sliderSteps = {

    $el: null,
    $swiper: null,
    $pagination: null,
    swiper: null,

    init: function() {
        this.$el = $(".slider-steps");
        this.$swiper = this.$el.find(".swiper-container");

        this.swiper = new Swiper(this.$swiper[0], {
            spaceBetween: 0,
            //effect: 'fade',
            autoplay: 7000,
            //simulateTouch: false,
            loop: true,
            roundLengths: true,
            prevButton: this.$el.find('.slider__nav-prev'),
            nextButton: this.$el.find('.slider__nav-next'),
        });

        $(document).ready(function(){
            $('.nav-info img').resizeOnApproach({
                elementDefault: 60,
                elementClosest: 80,
                triggerDistance: 100
            });
        });

        this.connectEvents();
    },

    connectEvents: function() {

    }
};


app.sliderHouses = {

    $el: null,
    $swiper: null,
    $pagination: null,
    swiper: null,

    init: function() {
        this.$el = $(".slider-houses");
        this.$swiper = this.$el.find(".swiper-container");

        this.swiper = new Swiper(this.$swiper[0], {
            spaceBetween: 40,
            slidesPerView: 4,
            //effect: 'fade',
            autoplay: 7000,
            //simulateTouch: false,
            loop: true,
            roundLengths: true,
            prevButton: this.$el.find('.slider__nav-prev'),
            nextButton: this.$el.find('.slider__nav-next'),
            breakpoints: {
                1500: {
                    slidesPerView: 3,
                    spaceBetween: 40
                },
                1060: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                420: {
                    slidesPerView: 1,
                    spaceBetween: 20
                },
            }
        });

        this.connectEvents();
    },

    connectEvents: function() {

    }
};

app.sliderCertificate = {

    $el: null,
    $swiper: null,
    $pagination: null,
    swiper: null,

    init: function() {
        this.$el = $(".slider-certificate");
        this.$swiper = this.$el.find(".swiper-container");

        this.swiper = new Swiper(this.$swiper[0], {
            // autoplay: 7000,
            loop: true,
            roundLengths: true,
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 20,
            prevButton: this.$el.find('.slider__nav-prev'),
            nextButton: this.$el.find('.slider__nav-next'),
            breakpoints: {
                1400: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                }
            }
        });

        this.connectEvents();
    },

    connectEvents: function() {

    }
};

app.sliderClientOpinion = {

    $el: null,
    $swiper: null,
    $pagination: null,
    swiper: null,

    init: function() {
        this.$el = $(".slider-client-opinion");
        this.$swiper = this.$el.find(".swiper-container");

        this.swiper = new Swiper(this.$swiper[0], {
            spaceBetween: 0,
            //effect: 'fade',
            autoplay: 7000,
            //simulateTouch: false,
            loop: true,
            roundLengths: true,
            prevButton: this.$el.find('.slider__nav-prev'),
            nextButton: this.$el.find('.slider__nav-next'),
        });

        $(document).ready(function(){
            $('.nav-info img').resizeOnApproach({
                elementDefault: 60,
                elementClosest: 80,
                triggerDistance: 100
            });
        });

        this.connectEvents();
    },

    connectEvents: function() {

    }
};

app.sliderProjects = {
    $el: null,
    $swiper: null,
    $pagination: null,
    swiper: null,
    $gridContainer: null,

    init: function() {
        this.$el = $(".gallery");
        this.$swiper = this.$el.find(".swiper-container");

        this.swiper = new Swiper(this.$swiper[0], {
            spaceBetween: 0,
            loop: true,
            roundLengths: true,
            prevButton: this.$el.find('.gallery__nav-prev'),
            nextButton: this.$el.find('.gallery__nav-next'),
        });

        this.connectEvents();
    },

    connectEvents: function() {
        this.$swiper.find("img.swiper-slide").on("click", $.proxy(this.slideOnClick, this));
    },

    slideOnClick: function() {
        var index = this.swiper.realIndex;
        this.$gridContainer = $(".js-silde-container");
        this.$gridContainer.find(".grid-box__gallery-item:eq("+ index +") a").trigger("click");
    }
};

app.navMobile = {

    $el: null,
    init: function() {

        this.$el = $(".nav-mobile");
        this.$items = this.$el.find(".nav-mobile__item--toggle");
        this.connectEvents();
    },

    connectEvents: function() {
        this.$items.on("click", "> a", $.proxy(this.toggle, this));
    },

    toggle: function(e) {
        var $target = $(e.delegateTarget),
            timeout = this.$items.hasClass("nav-mobile__item--active") > 0 ? 300 : 0;

        if (!$target.hasClass("nav-mobile__item--active")) {
            $target.siblings().removeClass("nav-mobile__item--active");
            setTimeout(function() {
                $target.addClass("nav-mobile__item--active");
            }, timeout);
        } else {
            $target.removeClass("nav-mobile__item--active");
        }

        return false;
    }
};


app.tabs = {

    $el: null,

    init: function() {
        this.$el = $(".tabs");
        this.$mobTrigger = this.$el.find(".tabs__trigger");

        this.connectEvents();
    },

    connectEvents: function() {
        this.$mobTrigger.on("click", $.proxy(this.mobTriggerOnClick, this));
    },

    mobTriggerOnClick: function(e) {
        if (window.innerWidth < 992) {
            var $target = $(e.currentTarget),
                animation = $target.hasClass("tabs__trigger--active") ? 'slideUp' : 'slideDown';

            $target.toggleClass("tabs__trigger--active");
            $target.siblings(".tabs__list").slideToggle();
            //$target.siblings(".tabs__list").velocity(animation);
        }
    }

};


app.articleList = {

    $el: null,
    $trigger: null,

    init: function() {
        this.$el = $(".article-list");
        this.$trigger = this.$el.find(".js-article-list__more");

        this.connectEvents();
    },

    connectEvents: function() {
        this.$trigger.on("click", $.proxy(this.toggle, this));
    },

    toggle: function(e) {
        var $target = $(e.currentTarget),
            $hiddenText = $target.siblings(".article-list__text-hidden"),
            $article = $target.closest(".article-list__item").attr('id');

        $target.closest(".article-list__item").toggleClass("active");
        $hiddenText.slideToggle();
        
        $('html, body').animate({
            scrollTop: $("#"+$article).offset().top
        }, 500, "easeOutCirc");
    }
};


app.accordion = {

    $el: null,

    init: function() {
        this.$el = $(".acc");
        this.$trigger = this.$el.find(".acc__header");

        this.connectEvents();
    },

    connectEvents: function() {
        this.$trigger.on("click", $.proxy(this.toggle, this));
    },

    toggle: function(e) {
        var $target = $(e.currentTarget);

        $target.parent().toggleClass("active");
        $target.siblings(".acc__content").slideToggle();
    }
};

app.map = {
    $el: null,
    $maps: null,

    init: function() {
        this.$el = $(".contact-map");
        this.$maps = this.$el.find(".js-map");

        this.mapInit();
    },

    mapInit: function() {

        this.$maps.each(function() {
            var mapElement = this,
                lat = '50.223813',
                long = '19.234824',
                mapOptions = {
                    center: new google.maps.LatLng(lat, long),
                    scrollwheel: false,
                    zoom: 17,
                    zoomControl: true,
                    scaleControl: true
                };

            var map = new google.maps.Map(mapElement, mapOptions);
            var marker = new google.maps.Marker({
                //icon: markericon,
                position: new google.maps.LatLng(lat, long),
                map: map
            });
        });

    }
};

app.msnry = {
    $el: null,
    $item: null,
    $elAppended: null,

    init: function() {
        this.$el = $('.grid--masonry.grid--hidden');
        this.$elAppended = $('.grid--masonry.grid--appended');
        this.$items = this.$el.find('.grid__item');

        this.$elAppended.masonry({
            columnWidth: '.grid__sizer',
            itemSelector: '.grid__item',
            gutter: '.grid__gutter-sizer',
            percentPosition: true
        });

        this.$elAppended.append(this.$items).masonry( 'appended', this.$items );
    }
};

app.galleryMasonry = {
    init: function() {
        $('.js-grid-box-gallery').masonry({
            itemSelector: '.grid-box__gallery-item',
            columnWidth: 128.4,
            isFitWidth: true,
            gutter: 20
        });
    }
};

app.noUi = {
    $el: null,

    init: function() {
        $el = $('#skipstep');

        var skipSlider,
            skipValues;

        skipSlider = document.getElementById('skipstep');

        noUiSlider.create(skipSlider, {
            range: {
                'min': 100,
                '25%': 110,
                '50%': 120,
                '75%': 130,
                'max': 140
            },
            format: {
                to: function ( value ) {
                    return value + ' m2';
                },
                from: function ( value ) {
                    return value.replace(' m2', '');
                }
            },
            snap: true,
            start: [100, 140]
        });

        skipValues = [
            document.getElementById('skip-value-lower'),
            document.getElementById('skip-value-upper')
        ];

        skipSlider.noUiSlider.on('update', function( values, handle ) {
            skipValues[handle].innerHTML = values[handle];
        });
    }
};

app.gridGallery = {
    $el: null,

    init: function() {
        var $gallery = $(".js-gallery");

        $gallery.lightGallery({
            selector: 'a',
            thumbnail: true,
            animateThumb: false,
            showThumbByDefault: false
        });
    }
};

app.houseGallery = {
    $el: null,

    init: function() {
        var $gallery = $(".js-house-gallery");

        $gallery.lightGallery({
            selector: 'a',
            thumbnail: true,
            animateThumb: true
        });
    }
};

app.scrollBarGallery1 = {
    $el: null,

    init: function () {

        var swpr = new Swiper('.js-scrollbar1', {
            scrollbar: '.swiper-scrollbar',
            scrollbarDraggable: true,
            direction: 'vertical',
            slidesPerView: 'auto',
            mousewheelControl: true,
            freeMode: true,
            scrollbarHide: false
        });

        $('a[href="#details"]').on('shown.bs.tab', function (e) {
            swpr.update();
            if (window.innerWidth < 1200) {
                $('.gallery__grid').hide();
            }
        });
    }
};

app.scrollBarGallery2 = {
    $el: null,

    init: function () {

        var swpr = new Swiper('.js-scrollbar2', {
            scrollbar: '.swiper-scrollbar',
            scrollbarDraggable: true,
            direction: 'vertical',
            slidesPerView: 'auto',
            mousewheelControl: true,
            freeMode: true,
            scrollbarHide: false
        });

        $('a[href="#description"]').on('shown.bs.tab', function (e) {
            swpr.update();
            if (window.innerWidth < 1200) {
                $('.gallery__grid').hide();
            }
        });
        $('a[href="#photos"]').on('shown.bs.tab', function (e) {
            $('.gallery__grid').show();
            $('.js-grid-box-gallery').masonry();
        });
    }
};

app.galleryColumnEqual = {

    init: function() {
        var $photoContainer,
            $scrollWindow,
            columnHeight;

        $photoContainer = $('.gallery__photo');
        $scrollWindow = $('.gallery__tabs .swiper-container');
        columnHeight = $photoContainer.height();

        $scrollWindow.height(columnHeight - 69);
    }
};



$(function() {
    app.init();
});